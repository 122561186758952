import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { LocalizedButton } from "../../language/LocalizedButton";

// core components

function IndexHeader({ language }) {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header page-header-fit"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("../../../images/bgs/citylove_map_grey.jpg") + ")"
        }}
      >
        <div className="content-center">
          <Container>
            <div
                className="ml-auto mr-auto 0mb-5"
                style={{
                width: 150,
                height: 150,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                    "url(" +
                    require("../../../images/logos/citylove_logo_full.svg") +
                    ")"
                }}
            ></div>
            <div
                className="mb-5">
                <h2 className="title">
                    Show some citylove –<br />
                    {/* Gutscheine zum Freuen, Schenken, Helfen! */}
                </h2>
                <h5>
                Danke, dass ihr während der Pandemie so fleißig den lokalen Handel unterstützt habt. Gemeinsam konnten wir  Unternehmerinnen und Unternehmern aus unserer Stadt durch die Krise helfen.
Für uns ist Citylove damit aber nicht beendet. Wir arbeiten schon mit Hochdruck an einer neuen innovativen Idee mit der ihr auch weiterhin in eurer City und auch virtuell Love verteilen könnt.
<br/><br/> Stay tuned.<br/> More to come soon!
                </h5>
            </div>
            {/* <LocalizedButton
                className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
                color="danger"
                language={language}
                to="/how-to"
            >
                So funktioniert's
            </LocalizedButton> */}
            <LocalizedButton
                className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
                color="danger"
                language={language}
                to="/joinin"
            >
                Partner werden
            </LocalizedButton>
            <LocalizedButton
                className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
                color="danger"
                language={language}
                to="/disclaimer"
            >
                AGB
            </LocalizedButton>
            <LocalizedButton
                className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
                color="danger"
                language={language}
                to="/about"
            >
                Über
            </LocalizedButton>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
