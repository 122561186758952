import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function renderImage(file, alt, src) {
  let img = file? file.node.childImageSharp.fluid : "images/citylove/website/Voucher White@1x.jpg"
  return <Img fluid={img} alt={alt} />;
}

export default ({ src, alt, maxWidth }) => {

    // const callRender = (images) => {
  //   if(image.node.relativePath) {
  //     renderImage(images.edges.find(image => image.node.relativePath === src).node, alt)
  //   } else console.log("Missing image", image);
  // }

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find(image => {
            //if(!image.node) console.log("image", image);
            return image.node.relativePath === src
          }),
          alt, src
        )
      }
    />
  );
};
