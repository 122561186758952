import React, { useEffect, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import {
  Col,
  Form,
  Input,
  Row,
  Card,
  CardHeader,
  InputGroup
} from "reactstrap";
import ShopCard from "./ShopCard.js";
import FilterItemGroup from "./FilterItemGroup.js";
import { localize } from "src/components/language/config";
let gaSt = null;


function toggleArrayItem(arr, item) {
  return arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item];
}

const ShopFilter = ({ data }) => {
  const [companies, setCompanies] = useState();
  const [query, setQuery] = useState("");

  const [allTypes, setAllTypes] = useState([]);
  const [type, setType] = useState(false);
  const [typesSelected, setTypesSelected] = useState([]);

  const [allCities, setAllCities] = useState([]);
  const [city, setCity] = useState(false);
  const [citiesSelected, setCitiesSelected] = useState([]);
  
  // window.addEventListener('popstate', function(event) {
  //   console.log('popstate fired!');

  // });

  // window.onhashchange = function() { 
  //   console.log('onhashchange fired!');
  //   //code  
  // }

  useEffect(() => {
    setCompanies(data.allGoogleSpreadsheetShops.edges);
    loadCities();
    loadTypes();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('shop')) setQuery(urlParams.get('shop'));



  }, []);

  useEffect(() => {
    if (
      query === "" &&
      citiesSelected.length === 0 &&
      typesSelected.length === 0
    )
      setCompanies(data.allGoogleSpreadsheetShops.edges);
    else {
      //console.log("filter", citiesSelected.length, typesSelected.length);
      setCompanies(
        data.allGoogleSpreadsheetShops.edges.filter(company => {
          let showCity = company.node.city && citiesSelected.length === 0 ? true : citiesSelected.includes(company.node.city);
          let showType = company.node.type && typesSelected.length === 0 ? true : typesSelected.includes(company.node.type);
          return (
            (company.node.name.toLowerCase().includes(query.toLowerCase()) || 
                company.node.description.toLowerCase().includes(query.toLowerCase()) ||
                company.node.city.toLowerCase().includes(query.toLowerCase()) ||
                company.node.type.toLowerCase().includes(query.toLowerCase())) 
              && showCity && showType
          );
        })
      );
      setGAEvent(query.toLowerCase());
    }
  }, [query, typesSelected, citiesSelected]);

  const setGAEvent = (searchWord) => {

    if(gaSt) window.clearTimeout(gaSt);
    gaSt = window.setTimeout(()=> {
      if(window.gtag) window.gtag('event', 'search', { 'search_term': searchWord });
      //console.log("window.setTimeout", searchWord);
    }, 2000);

  }



  const toggleItem = (data, category, caption) => {

    //console.log("toggleItem",  category, caption);
    if (category === "city"){ 
      if(window.gtag && !citiesSelected[caption]) {
        if(window.gtag) window.gtag('event', 'category', {
          'event_category' : 'selectCity',
          'event_label' : caption
        });
      
      }
      setCitiesSelected(toggleArrayItem(citiesSelected, caption));
    }

    if (category === "type"){
      if(window.gtag && !typesSelected[caption]) {
        if(window.gtag) window.gtag('event', 'category', {
          'event_category' : 'selectBranch',
          'event_label' : caption
        });
      }
      setTypesSelected(toggleArrayItem(typesSelected, caption));
    }


  };
  const renderCompanies = () => {
    return companies?.map(company => {
      return <ShopCard key={company.node.id} {...company.node} />;
    });
  };

  const loadCities = () => {
    let temp = [];
    data.allGoogleSpreadsheetCity.edges.map(option => {
      temp.push(option.node.city);
    });
    setAllCities(temp);
    setCitiesSelected([]);
  };

  const loadTypes = () => {
    let temp = [];
    data.allGoogleSpreadsheetType.edges.map(option =>
      temp.push(option.node.type)
    );
    setAllTypes(temp);
    setTypesSelected([]);
  };

  const getItems = items => {
    let res = [];
    items.map(aCity => res.push({ caption: aCity, active: false }));
    return res;
  };

  return (
    <>
      <Row>
        <Col className="ml-auto mr-auto text-center">
          <Form
            className="form-inline search-form form-citylove-search"
            role="search" onSubmit={e => { e.preventDefault(); }}
          >
            <InputGroup className="no-border">
              {/* <span
                className="input-group-addon addon-xtreme no-border"
                id="basic-addon1"
              >
                <i className="fa fa-search" />
              </span> */}
              <Input
                aria-describedby="basic-addon1"
                className=""
                placeholder={localize("shopFilter.search.placeholder")}
                value={query}
                onChange={e => setQuery(e.target.value)}
                type="text"
              />
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col lg="3" xl="3">
          <Card className="card-refine card-citylove-filter">
            <div
              aria-expanded={true}
              aria-multiselectable={true}
              className="panel-group"
              id="accordion"
            >
              <CardHeader>
                <h5 className="mb-0 panel-title">Filter</h5>
              </CardHeader>
              <FilterItemGroup
                isOpen={city}
                toggle={toggleItem}
                category="city"
                caption={localize("shopFilter.city.caption")}
                options={getItems(allCities)}
                setOpen={setCity}
              ></FilterItemGroup>
              <FilterItemGroup
                isOpen={type}
                toggle={toggleItem}
                category="type"
                caption={localize("shopFilter.type.caption")}
                options={getItems(allTypes)}
                setOpen={setType}
              ></FilterItemGroup>
            </div>
          </Card>
          {/* end card */}
        </Col>
        <Col lg="9" xl="9">{renderCompanies()}</Col>
      </Row>
    </>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allGoogleSpreadsheetShops(filter: {status: {eq: "6. Online"}}, sort: {fields: ranking}) {
          edges {
            node {
              id
              name
              contactPerson
              slogan
              address
              description
              email
              facebook
              instagram
              kundenart
              bildWebsite
              mehrText
              id
              type
              website
              city
              phone
              giftupItemIds
            }
          }
        }
        allGoogleSpreadsheetCity {
          edges {
            node {
              city
            }
          }
        }
        allGoogleSpreadsheetType {
          edges {
            node {
              type
            }
          }
        }
      }
    `}
    render={data => <ShopFilter data={data} {...props} />}
  />
);
