import React, { useEffect } from "react";

import { Button, Modal } from "reactstrap";
import { localize } from "src/components/language/config";

const Giftup = props => {
  const [scrollingLongContent, setScrollingLongContent] = React.useState(false);

  const guScript = "https://cdn.giftup.app/dist/gift-up.js";

  useEffect(() => {

    let script = document.createElement("script")
    script.src =  guScript;
    script.async = true
    document.body.appendChild(script);

  }, [scrollingLongContent]);

  const showGiftup = () => {
    if(window.gtag) window.gtag('event', 'giftup', {
      'event_category' : 'open',
      'event_label' : props.title
    });

    setScrollingLongContent(true)
  }

  return (
    <>
      <Button
        color="primary btn-round mr-2 mb-2"
        type="button"
        onClick={showGiftup}
      >
        {localize("giftup.buyvoucher.caption")}
      </Button>
      <Modal
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        isOpen={scrollingLongContent}
        toggle={() => setScrollingLongContent(false)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setScrollingLongContent(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h3 className="modal-title" id="exampleModalLongTitle">
            {props.title}
          </h3>
        </div>
        <div className="modal-body">
          <div
            data-site-id="752ba5bb-95de-4053-b37e-8509178a92bf"
            data-platform="Other"
            className="gift-up-target"
            data-product-id={props.productId}
          ></div>
        </div>

        {<div class="modal-footer content-center">
          <p class="ml-auto mr-auto mt-3 mb-3">Kein Inhalt? Bitte überprüfe deinen Adblocker.</p>
          </div>}
      </Modal>
    </>
  );
};

export default Giftup;

