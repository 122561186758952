import React from "react";
import Layout from "../components/layout";
// reactstrap components
import { Container } from "reactstrap";

import IndexHeader from "../components/Custom/Headers/IndexHeader.js";
// core components
import ShopFilter from "../components/Custom/ShopFilter/ShopFilter.js";
import withUIKit from "../withUIKit";
import CustomNavbar from "../components/Custom/Navbar";

function Index(props) {
  React.useEffect(() => {
    document.documentElement.classList.remove("nav-open");
  }, []);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("search-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("search-page");
    };
  });
  return (
    <Layout {...props} location={props.location}>
      <CustomNavbar {...props} />
      <IndexHeader {...props} />
      {/* <div className="wrapper">
        <div className="section ">
          <Container>
            <ShopFilter />
          </Container>
        </div>
      </div> */}
    </Layout>
  );
}

export default withUIKit(Index);
