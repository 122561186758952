import React from "react";
//import { localize } from "src/components/language/config";
// reactstrap components
import {
  Input,
  FormGroup,
  Label,
  Collapse,
  CardHeader,
  CardBody,
} from "reactstrap";

// core components

const FilterItemGroup = props => {
  const renderOptions = () => {
    return props.options?.map(aOption => {
      return (
        <FormGroup key={aOption.caption} check>
          <Label check>
            <Input
              defaultChecked={aOption.active}
              onClick={e =>
                props.toggle(e.target.checked, props.category, aOption.caption)
              }
              defaultValue=""
              type="checkbox"
            />
            {aOption.caption}
            <span className="form-check-sign" />
          </Label>
        </FormGroup>
      );
    });
  };

  return (
    <>
      <CardHeader className="card-collapse" id="clothingGear" role="tab">
        <h5 className="mb-0 panel-title">
          <a
            aria-expanded={props.isOpen}
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              props.setOpen(!props.isOpen);
            }}
          >
            {props.caption}
            <i className="nc-icon nc-minimal-down" />
          </a>
        </h5>
      </CardHeader>
      <Collapse isOpen={props.isOpen}>
        <CardBody>{renderOptions()}</CardBody>
      </Collapse>
    </>
  );
};

export default FilterItemGroup;
